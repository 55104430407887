import "./footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footerTop">
        <div className="footerTopLeft">
          <a href="mailto:support@adora.ink" className="footerTopLeftTitle">
            Contact Us
          </a>
          <a
            href="https://whitepaper.adora.ink/economy"
            className="footerTopLeftTitle"
          >
            Tokenomics
          </a>
          <a
            href="https://whitepaper.adora.ink/gameplay"
            className="footerTopLeftTitle"
          >
            Gameplay
          </a>
        </div>
        {/* <img src={require('../image/language.png')} alt=""/> */}
      </div>
      <div className="footerMin">
        <div className="footerMinLeft">
          <a
            href="https://whitepaper.adora.ink/gameplay/blueprints"
            className="footerMinLeftTitle"
          >
            Blueprints
          </a>
          <a
            href="https://whitepaper.adora.ink/gameplay/adornment"
            className="footerMinLeftTitle"
          >
            Adornment
          </a>
          <a
            href="https://whitepaper.adora.ink/gameplay/stats-and-skills"
            className="footerMinLeftTitle"
          >
            Stats and Skills
          </a>
          <a
            href="https://whitepaper.adora.ink/gameplay/stage-battle"
            className="footerMinLeftTitle"
          >
            Stage Battle
          </a>
        </div>
      </div>
      <div className="footerBottom">
        <div>Adora Ink</div>
        <div>All rights reserved</div>
        <div>2022</div>
      </div>
    </div>
  );
}

export default Footer;
