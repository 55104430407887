import { Carousel, List } from "antd";
import content from "../../image/content.png";
import { HomePageBannar } from "../../axios/api";
import React, { Component } from "react";
class Dom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      List: [
        {
          id: 1,
          attributes: {
            url: require("../../image/content2.png"),
          },
        },
        {
          id: 2,
          attributes: {
            url: require("../../image/content1.png"),
          },
        },
        {
          id: 3,
          attributes: {
            url: require("../../image/content.png"),
          },
        },
      ],
    };
  }
  getData = () => {
    HomePageBannar({
      populate: "*",
    }).then((res) => {
      this.setState({
        List: res.data.attributes.desktop_contents.data,
      });
    });
  };
  async componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Carousel
        effect="fade"
        className="Carousel-big"
        autoplay="false"
        dots={false}
        speed="1500"
      >
        {this.state.List.map((item, index) => {
          return (
            <div key={index} style={{ height: "100%" }}>
              <img alt="" src={item.attributes.url} style={{ width: "100%" }} />
            </div>
          );
        })}
      </Carousel>
    );
  }
}
// function Dom(){
// 	var List =[]
// 	const getData = ()=>{
// 		HomePageBannar({
// 			populate:'*'
// 		}).then(res=>{
// 			List = res.data.attributes.desktop_contents.data
// 			console.log(res)
// 		})
// 	}
// 	getData()

// 	return (
// 		<Carousel effect="fade" className="Carousel-big" autoplay="false" dots={false} speed="1500">
// 		{
// 			List.map((item,index)=>{
// 				return  <div key={index} style={{height:'100%'}}>
// 							<img alt="" src={item.attributes.url} style={{width:'100%'}}/>
// 						</div>
// 			})
// 		}
// 		</Carousel>
// 	)
// }

export default Dom;
