// import SSR from '../image/SSR.png';
// import adora from '../image/adora.png';
// import adoraStroke from '../image/adoraStroke.png';
// import Union from '../image/Union.png';
import content from "../image/content.png";
import dowload from "../image/dowload.png";
import Group from "../image/Group.png";
import googleplay from "../image/googleplay.png";
import Swipertops from "./swiperTop/swiperTops.js";
import React, { Component } from "react";

import { themeVideo } from "../axios/api.js";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      url: "",
      showVideo: true,
      current: 0,
    };
  }
  getVideo = () => {
    themeVideo({
      populate: "*",
    }).then((res) => {
      this.setState({
        url: res.data.attributes.desktop_contents.data.attributes.url,
      });
    });
  };
  componentDidMount() {
    this.getVideo();
  }
  LookVideo = () => {
    this.setState({
      showVideo: false,
      // videoUrl:item.video
    });
  };
  hideVideo = () => {
    this.refs.vidRef.pause();
    this.setState({
      showVideo: true,
    });
  };
  InPage = (i) => {
    if (i === 0) {
      document.querySelector("#header").scrollIntoView({ behavior: "smooth" });
    }
    if (i === 1) {
      document
        .querySelector("#bgSwiper")
        .scrollIntoView({ behavior: "smooth" });
    }
    if (i === 2) {
      document
        .querySelector("#clothingSwiper")
        .scrollIntoView({ behavior: "smooth" });
    }

    this.setState({
      current: i,
    });
  };
  render() {
    return (
      <div className="header-box">
        <div className="header" id="header">
          <div className="headers"></div>

          <div className="header-box-show">
            <div className="headerNav">
              <div
                className="navBox"
                onClick={() => {
                  this.InPage(0);
                }}
              >
                HOME
                <div
                  className="navBorder"
                  style={Object.assign({
                    display: this.state.current === 0 ? "block" : "none",
                  })}
                ></div>
              </div>
              <div
                className="navBox"
                onClick={() => {
                  this.InPage(2);
                }}
              >
                THEMES
                <div
                  className="navBorder"
                  style={Object.assign({
                    display: this.state.current === 2 ? "block" : "none",
                  })}
                ></div>
              </div>
              <div
                className="navBox"
                onClick={() => {
                  this.InPage(1);
                }}
              >
                PROGRESS
                <div
                  className="navBorder"
                  style={Object.assign({
                    display: this.state.current === 1 ? "block" : "none",
                  })}
                ></div>
              </div>
              <a
                href="https://whitepaper.adora.ink/"
                target="_blank"
                className="navBox"
                rel="noreferrer"
              >
                WHITEPAPER
              </a>
            </div>

            <div className="headerIcon">
              <a
                target="_blank"
                href="https://twitter.com/AdoraInk"
                rel="noreferrer"
              >
                <div className="iconBox">
                  <i className="fi fi-brands-twitter"></i>
                </div>
              </a>
              <a
                target="_blank"
                href="https://discord.gg/EwMn3aEMjh"
                rel="noreferrer"
              >
                <div className="iconBox" style={{ marginRight: 0 }}>
                  <i className="fi fi-brands-discord"></i>
                </div>
              </a>
              {/* <div className="iconBox">
								<i className="iconfont">&#xe695;</i>
							</div>
							<div className="iconBox">
								<i className="iconfont">&#xe883;</i>
							</div> */}
            </div>
          </div>
        </div>
        <div className="content">
          <Swipertops></Swipertops>
          <div className="dowloadWay">
            <div className="dowloadWays">
              <img src={dowload} alt="" className="dowloadWayImg" />
              <img src={Group} alt="" className="dowloadWayImg" />
              <img src={googleplay} alt="" className="dowloadWayImg" />
            </div>
            <div
              className="box-video"
              onClick={() => {
                this.LookVideo();
              }}
            >
              <i className="iconfont">&#xe87c;</i>
            </div>
          </div>
        </div>

        <div
          className="popup-video"
          style={Object.assign({
            opacity: this.state.showVideo ? 0 : 1,
            zIndex: this.state.showVideo ? "-1" : 10,
          })}
        >
          <div
            className="closeVideo"
            onClick={() => {
              this.hideVideo();
            }}
          ></div>
          <video
            controls
            className="video"
            src={this.state.url}
            ref="vidRef"
          ></video>
        </div>
      </div>
    );
  }
}
export default Header;
