import "./swiper.css";
import Swiper from "./swiper.js";
import Swiperbig from "./swiperbig.js";

import "./Component/footer.css";
import Footer from "./Component/footer.js";
import Header from "./Component/header.js";
// import rem from './utils/rem.js';

// import { Fullpage, FullpageItem } from "./fullpage.js";

import "./App.scss";
// import './rem.js'
import { useEffect, useRef } from "react";
import { HomePageBannar } from "./axios/api";

import transverse from "./image/transverse.png";
import Vector32 from "./image/Vector32.png";
import Vector31 from "./image/Vector31.png";

var w = document.documentElement.scrollWidth || document.body.scrollWidth;
var h = w / 1.77;
var top = w * 0.09;

function App() {
  // const [seed, setSeed] = useState(1);
  // const reset = () => {
  //     setSeed(Math.random());
  // }
  useEffect(() => {
    window.addEventListener("resize", resizeUpdata);
    // getData()
    // rem();
    return () => {
      window.removeEventListener("resize", resizeUpdata);
    };
  }, []);

  // const getData = ()=>{
  // 	HomePageBannar({
  // 		populate:'*'
  // 	}).then(res=>{
  // 		return <Swiperbig></Swiperbig>
  // 	})
  // }

  const resizeUpdata = (e) => {
    w = e.target.innerWidth;
    h = w / 1.77;
    top = w * 0.09;
    // this.refs.listHeight.height = h
    // console.log(h)
    // console.log(listHeight.current.clientHeight)
  };
  let DOM = (
    <div className="App">
      <Header></Header>
      <div className="contentTwo" id="contentTwo">
        <div className="contentTwoMain" id="contentTwoMain">
          <Swiperbig></Swiperbig>
        </div>
      </div>
      <div className="contentThree" id="contentThree">
        <div className="TwoTitle">
          <img src={Vector31} alt="" className="VectorLine" />
          <div className="ThreeTitle">DEVELOPMENT PROGRESS</div>
          <img src={Vector32} alt="" className="VectorLine" />
        </div>
        <Swiper></Swiper>
        <img src={transverse} alt="" className="transverse" />
      </div>
      <Footer></Footer>
    </div>
  );
  return DOM;
}

export default App;
