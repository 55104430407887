import React, { Component } from "react";
// import { LeftOutlined, RightOutlined} from '@ant-design/icons'
import circular from "./image/circular.png";
import circulars from "./image/circulars.png";
import { HomePageMainImage } from "./axios/api";

var w = document.documentElement.scrollWidth || document.body.scrollWidth;
var h = w / 1.77;
// console.log(h)

class Swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      carouselStyle: {},
      imageWidth: w,
      imageHeight: h,
      slideStyle: {},
      imgStyle: {
        transform: "scale(1.1)",
        transition: "all ease-out .1s",
      },
      defaultImgStyle: {
        transform: "scale(1)",
        transition: "all ease-out .1s",
      },
      speed: 0.8, // 不需要鼠标移入时可设置speed的值为2
      isUpload: false,
      items: [],
      showVideo: true,
      videoUrl: "",
    };
  }
  resizeUpdata = (e) => {
    w = e.target.innerWidth;
    h = w / 1.77;
    // console.log(h)
    this.setState({
      imageWidth: w,
      imageHeight: h,
    });
    // this.render()
    // console.log(h)
  };

  getData = () => {
    HomePageMainImage({
      populate: "*",
    }).then((res) => {
      let List = [];
      for (var i = 0; i < res.data.length; i++) {
        // console.log(res.data[i].attributes.font_color)
        let obj = {
          id: i,
          src: res.data[i].attributes.desktop_media.data.attributes.url,
          title: res.data[i].attributes.title,
          description: res.data[i].attributes.description,
          createdAt: res.data[i].attributes.date,
          subtitle: res.data[i].attributes.subtitle,
          video: res.data[i].attributes.desktop_video.data.attributes.url,
          color: res.data[i].attributes.font_color,
        };
        List.unshift(obj);
      }

      this.items = List;
      this.itemsLength = this.items.length;
      this.setState({
        items: List,
        isUpload: true,
      });
    });
  };
  turn = () => {
    // 轮播
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.go(1);
    }, 2 * 30000000);
  };
  go = (step) => {
    // 去哪  传入要动几个
    // console.log(this.state.currentIndex, 'currentIndex')
    let currentIndex = this.state.currentIndex + step; // 先加
    if (currentIndex >= this.itemsLength) {
      // 当等于最后一张时  越界回到0
      this.ul.style.transitionDuration = "0s";
      this.ul.style.left = 0;
      setTimeout(() => {
        // 等动画移除后并且回到了0点  再增加回动画时间（dom刷新一般是30s）
        this.ul.style.transitionDuration = this.state.speed + "s"; // 再增加回来这个动画
        currentIndex = 0; // 下一次到第一张图
        this.setState({ currentIndex });
      }, 30);
      return; //因为设置了setTimeout所以要等待setTimeout后再设置最新状态
    }
    if (currentIndex < 0) {
      // 当小于第一张时  回到最后一张
      this.ul.style.transitionDuration = ""; // 清除ul上的动画
      this.ul.style.left =
        (this.itemsLength - 1) * -1 * this.state.imageWidth + "px";
      setTimeout(() => {
        this.ul.style.transitionDuration = this.state.speed + "s";
        currentIndex = this.itemsLength - 1; // 小于第一张时跳转到最后一张
        this.setState({ currentIndex });
      }, 30);
      return;
    }
    this.ul.style.transitionDuration = this.state.speed + "s";
    const slideStyle = this.state.slideStyle;
    this.setState({
      currentIndex,
      slideStyle: Object.assign(slideStyle, {
        left: -1 * this.state.imageWidth * currentIndex + "px",
      }),
    });
  };
  stop = () => {
    clearInterval(this.timer);
    this.timer = null;
  };
  hideVideo = () => {
    this.refs.vidRef.pause();
    this.setState({
      showVideo: true,
    });
  };
  handleMouseEnter = (e, index) => {
    e.preventDefault();
    this.stop();
    if (index) {
      // this.setState({
      //     imgStyle: {
      //         transform: 'scale(1.1)',
      //         transition: 'all linear .3s'
      //     }
      // })
      this.hoverIndex = index - 1;
    } else {
      this.hoverIndex = null;
    }
  };
  handleMouseLeave = (e, index) => {
    e.preventDefault();
    this.turn(true);
    this.ul.style.transitionDuration = this.state.speed + "s";
    if (index) {
      this.setState({
        imgStyle: {
          transform: "scale(1)",
          transition: "all ease-out .3s",
        },
      });
    }
    this.hoverIndex = null;
  };
  linkToProduct = (item) => {
    // console.log(id,this.$route, 'id')
    this.setState({
      showVideo: false,
      videoUrl: item.video,
    });
    clearInterval(this.timer);
    this.timer = null;
  };
  async componentDidMount() {
    // console.log(1)

    this.items = [
      { id: 1, src: require("./image/swiper1.png") },
      { id: 2, src: require("./image/swiper1.png") },
      { id: 3, src: require("./image/swiper2.png") },
      { id: 4, src: require("./image/swiper2.png") },
      { id: 5, src: require("./image/swiper3.png") },
      { id: 6, src: require("./image/swiper3.png") },
      { id: 7, src: require("./image/swiper1.png") },
      { id: 8, src: require("./image/swiper1.png") },
      { id: 9, src: require("./image/swiper2.png") },
      { id: 10, src: require("./image/swiper2.png") },
    ];
    this.getData();

    window.addEventListener("resize", this.resizeUpdata);
    this.setState({
      carouselStyle: {
        width: this.state.imageWidth + "px",
      },
      slideStyle: {
        // 宽度增加1倍
        width: this.state.imageWidth * this.items.length * 2 + "px",
        left: 0,
      },
    });
    this.hoverIndex = null;

    this.turn();
    this.itemsLength = this.items.length;
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener("resize", this.resizeUpdata);
  }
  render() {
    // if(!this.state.isUpload) return
    // console.log(this.items)
    if (this.items && this.items.length) {
      return (
        <div
          className={"home-box"}
          style={Object.assign({
            height: this.state.imageHeight + "px",
            minWidth: "1300px",
            minHeight: "735px",
          })}
        >
          <div
            className={" carousels"}
            style={Object.assign({ overflow: "hidden", width: "100%" })}
          >
            <ul
              className="slide"
              style={Object.assign({ padding: 0 }, this.state.slideStyle)}
              ref={(ul) => {
                this.ul = ul;
              }}
            >
              {this.items &&
                this.items.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                      className={"img-boxs"}
                      style={Object.assign({
                        width: this.state.imageWidth,
                        background:
                          "url(" + item.src + ") no-repeat center /cover",
                        minWidth: "1300px",
                      })}
                      onMouseEnter={(e) => {
                        this.handleMouseEnter(e, index + 1);
                      }}
                      onMouseLeave={(e) => {
                        this.handleMouseLeave(e, index + 1);
                      }}
                    >
                      <div className="box-title" style={{ color: item.color }}>
                        <div>{item.title}</div>
                        <div className="box-title-s">Theme Story</div>
                      </div>

                      <div className="box-text" style={{ color: item.color }}>
                        <div>{item.description}</div>
                        <div className="box-SSR">Game Feature #{index + 1}</div>
                        <div className="box-shadow">{item.subtitle}</div>
                        {/* <div className="box-video"
												onClick={() => {this.linkToProduct(item)}}
												>
													<i className="iconfont">&#xe87c;</i>
											</div>*/}
                      </div>
                    </li>
                  );
                })}
            </ul>
            <div
              className={"left-controls"}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={() => {
                this.go(-1);
              }}
              style={{
                display: this.state.currentIndex === 0 ? "none" : "flex",
              }}
            >
              <i className="iconfont">&#xe653;</i>
            </div>
            <div
              className={"right-controls"}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={() => {
                this.go(1);
              }}
              style={{
                display:
                  this.state.currentIndex >= this.items.length - 1
                    ? "none"
                    : "flex",
              }}
            >
              <i className="iconfont">&#xe775;</i>
            </div>

            <div
              className="SwiperNum"
              style={Object.assign({ width: this.itemsLength * 60 + "px" })}
            >
              {this.items &&
                this.items.map((item, index) => {
                  return (
                    <img
                      src={
                        this.state.currentIndex === index ? circular : circulars
                      }
                      alt=""
                      key={"current_" + index}
                    />
                  );
                })}
            </div>

            <div
              className="video-box"
              style={Object.assign({
                opacity: this.state.showVideo ? 0 : 1,
                zIndex: this.state.showVideo ? "-1" : 10,
              })}
            >
              <div
                className="closeVideo"
                onClick={() => {
                  this.hideVideo();
                }}
              ></div>
              <video
                controls
                className="video"
                src={this.state.videoUrl}
                ref="vidRef"
              ></video>
            </div>
            <div id="bgSwiper" className="positionSwiper"></div>
            <div className="clothingSwiper" id="clothingSwiper"></div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
Swiper.defaultProps = {};
export default Swiper;
