import http from "./request";

/**
 * 获取首页列表
 */
function HomePageBannar(data) {
  return http("get", "/api/home-page-banner", data);
}

/**
 * 获取大轮播图片
 */
function HomePageMainImage(data) {
  return http("get", "/api/home-page-main-images", data);
}

/**
 * 获取小轮播图片
 */
function HomePageProgressImage(data) {
  return http("get", "/api/home-page-progress-images", data);
}

/**
 * 获取视频
 */
function themeVideo(data) {
  return http("get", "/api/theme-video", data);
}

export { HomePageBannar, HomePageMainImage, HomePageProgressImage, themeVideo };
