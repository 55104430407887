import React, { Component } from "react";
// import { LeftOutlined, RightOutlined} from '@ant-design/icons'
import { HomePageProgressImage } from "./axios/api";

var ImgWidth =
  document.documentElement.scrollWidth || document.body.scrollWidth;
var ws = ImgWidth / 3.59;
var h = ws / 1.19;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      carouselStyle: {},
      imageWidth: ws,
      imageHeight: h,
      slideStyle: {},
      imgStyle: {
        transform: "scale(1.1)",
        transition: "all ease-out .1s",
      },
      defaultImgStyle: {
        transform: "scale(1)",
        transition: "all ease-out .1s",
      },
      speed: 0.5, // 不需要鼠标移入时可设置speed的值为2
      isUpload: false,
    };
  }
  resizeUpdata = (e) => {
    ImgWidth = e.target.innerWidth;
    ws = ImgWidth / 3.59;
    h = ws / 1.19;

    this.setState({
      imageWidth: ws,
      imageHeight: h,
    });
    // this.render()
    // console.log(h)
  };

  getData = async () => {
    await HomePageProgressImage({
      populate: "*",
      sort: "date:desc",
    }).then((res) => {
      let List = [];
      for (var i = 0; i < res.data.length; i++) {
        console.log();
        let Month = parseInt(
          res.data[i].attributes.date.split("T")[0].split("-")[1],
        );
        let day = res.data[i].attributes.date.split("T")[0].split("-")[2];
        let showDate = "";
        if (Month === 1) {
          showDate = "January " + day + "th";
        }
        if (Month === 2) {
          showDate = "February " + day + "th";
        }
        if (Month === 3) {
          showDate = "March " + day + "th";
        }
        if (Month === 4) {
          showDate = "April " + day + "th";
        }
        if (Month === 5) {
          showDate = "May " + day + "th";
        }
        if (Month === 6) {
          showDate = "June " + day + "th";
        }
        if (Month === 7) {
          showDate = "July " + day + "th";
        }
        if (Month === 8) {
          showDate = "August " + day + "th";
        }
        if (Month === 9) {
          showDate = "September " + day + "th";
        }
        if (Month === 10) {
          showDate = "October " + day + "th";
        }
        if (Month === 11) {
          showDate = "November " + day + "th";
        }
        if (Month === 12) {
          showDate = "December " + day + "th";
        }
        let obj = {
          id: i,
          src: res.data[i].attributes.desktop_media.data.attributes.url,
          title: res.data[i].attributes.title,
          description: res.data[i].attributes.description,
          createdAt: showDate,
          subtitle: res.data[i].attributes.subtitle,
          font_color: res.data[i].attributes.font_color,
        };
        List.push(obj);
      }

      this.items = List;
      this.itemsLength = this.items.length;
      this.setState({
        isUpload: true,
      });
    });
  };
  turn = () => {
    // 轮播
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.go(1);
    }, 2 * 30000000);
  };
  go = (step) => {
    // 去哪  传入要动几个
    // console.log(this.state.currentIndex, 'currentIndex')
    let currentIndex = this.state.currentIndex + step; // 先加
    if (currentIndex >= this.itemsLength) {
      // 当等于最后一张时  越界回到0
      this.ul.style.transitionDuration = "0s";
      this.ul.style.left = 0;
      setTimeout(() => {
        // 等动画移除后并且回到了0点  再增加回动画时间（dom刷新一般是30s）
        this.ul.style.transitionDuration = this.state.speed + "s"; // 再增加回来这个动画
        currentIndex = 0; // 下一次到第一张图
        this.setState({ currentIndex });
      }, 30);
      return; //因为设置了setTimeout所以要等待setTimeout后再设置最新状态
    }
    if (currentIndex < 0) {
      // 当小于第一张时  回到最后一张
      this.ul.style.transitionDuration = ""; // 清除ul上的动画
      this.ul.style.left =
        (this.itemsLength - 1) * -1 * this.state.imageWidth + "px";
      setTimeout(() => {
        this.ul.style.transitionDuration = this.state.speed + "s";
        currentIndex = this.itemsLength - 1; // 小于第一张时跳转到最后一张
        this.setState({ currentIndex });
      }, 30);
      return;
    }
    this.ul.style.transitionDuration = this.state.speed + "s";
    const slideStyle = this.state.slideStyle;
    this.setState({
      currentIndex,
      slideStyle: Object.assign(slideStyle, {
        left:
          -1 * this.state.imageWidth * currentIndex - currentIndex * 120 + "px",
      }),
    });
  };
  stop = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  handleMouseEnter = (e, index) => {
    e.preventDefault();
    this.stop();
    if (index) {
      // this.setState({
      //     imgStyle: {
      //         transform: 'scale(1.1)',
      //         transition: 'all linear .3s'
      //     }
      // })
      this.hoverIndex = index - 1;
    } else {
      this.hoverIndex = null;
    }
  };
  handleMouseLeave = (e, index) => {
    e.preventDefault();
    this.turn(true);
    this.ul.style.transitionDuration = this.state.speed + "s";
    if (index) {
      this.setState({
        imgStyle: {
          transform: "scale(1)",
          transition: "all ease-out .1s",
        },
      });
    }
    this.hoverIndex = null;
  };
  linkToProduct = (id) => {
    // console.log(id,this.$route, 'id')
    clearInterval(this.timer);
    this.timer = null;
    window.location.hash = `#/products/${id}`;
  };
  componentDidMount() {
    this.items = [
      { id: 1, src: require("./image/Group1.png") },
      { id: 2, src: require("./image/Group2.png") },
      { id: 3, src: require("./image/Group1.png") },
      { id: 4, src: require("./image/Group2.png") },
      { id: 5, src: require("./image/Group2.png") },
      { id: 6, src: require("./image/Group2.png") },
      { id: 7, src: require("./image/Group2.png") },
      { id: 8, src: require("./image/Group2.png") },
      { id: 9, src: require("./image/Group2.png") },
      { id: 10, src: require("./image/Group2.png") },
    ];
    window.addEventListener("resize", this.resizeUpdata);
    this.itemsLength = this.items.length;

    this.setState({
      carouselStyle: {
        width: this.state.imageWidth + "px",
      },
      slideStyle: {
        // 宽度增加1倍
        width: this.state.imageWidth * this.items.length * 2 + "px",
        left: 0,
      },
    });
    this.hoverIndex = null;
    this.turn();
    this.getData();
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener("resize", this.resizeUpdata);
  }
  render() {
    if (this.items && this.items.length) {
      return (
        <div className={"home-box"}>
          <div
            className={"carousel"}
            style={Object.assign({
              overflow: "hidden",
              height: h + "px",
              width: "100%",
              marginTop: "20px",
              minHeight: "380px",
            })}
          >
            <ul
              className="slide"
              style={Object.assign({ padding: 0 }, this.state.slideStyle)}
              ref={(ul) => {
                this.ul = ul;
              }}
            >
              {this.items &&
                this.items.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                      className={"img-box"}
                      style={Object.assign({
                        width: this.state.imageWidth,
                        background:
                          "url(" + item.src + ") no-repeat 0 0 /100% 100%",
                        color: item.font_color,
                      })}
                      onMouseEnter={(e) => {
                        this.handleMouseEnter(e, index + 1);
                      }}
                      onMouseLeave={(e) => {
                        this.handleMouseLeave(e, index + 1);
                      }}
                      onClick={() => {
                        this.linkToProduct(item.id);
                      }}
                    >
                      <div className="img-content">
                        <div className="content-title">{item.subtitle}</div>
                        <div className="content-title-big">{item.title}</div>
                        <div className="content-text">{item.description}</div>
                        <div className="content-time">{item.createdAt}</div>
                      </div>
                    </li>
                  );
                })}
            </ul>
            <div
              className={"left-control"}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={() => {
                this.go(-1);
              }}
              style={{
                display: this.state.currentIndex === 0 ? "none" : "flex",
              }}
            >
              <i className="iconfont">&#xe653;</i>
            </div>
            <div
              className={"right-control"}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={() => {
                this.go(1);
              }}
              style={{
                display:
                  this.state.currentIndex >= this.items.length - 2
                    ? "none"
                    : "flex",
              }}
            >
              <i className="iconfont">&#xe775;</i>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
Home.defaultProps = {};
export default Home;
